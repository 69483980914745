p-togglebutton {
  .p-togglebutton.p-button.p-highlight {
    background: #3b82f6;
    border-color: #3b82f6;
    color: #ffffff;
    &:hover,
    :focus {
      background: #2563eb;
      border-color: #2563eb;
      color: #ffffff;
      .p-button-icon-left {
        color: white;
      }
    }
    .p-button-icon-left {
      color: white;
    }
  }
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight) {
    background: #ffffff;
    border: 1px solid #ced4da;
    color: #495057;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    &:hover,
    :focus {
      background: #e9ecef;
      border-color: #ced4da;
      color: #495057;
      .p-button-icon-left {
        color: #495057;
      }
    }
    .p-button-icon-left {
      color: #343a40;
    }
  }
}
