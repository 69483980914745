* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: $scale;
}

body {
  font-family: var(--font-family);
  color: var(--text-color);
  background-color: var(--surface-ground);
  margin: 0;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

.layout-wrapper {
  min-height: 100vh;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  margin: 15px 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
