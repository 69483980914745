.layout-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  padding: 7rem 2rem 1rem 2rem;
  transition: margin-left $transitionDuration;
}

.layout-main {
  flex: 1 1 auto;
}

@media (max-width: 576px) {
  .layout-main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding: 6rem 1rem 1rem 1rem;
    transition: margin-left $transitionDuration;
  }
}
